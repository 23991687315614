import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  input() {
    this.inputTarget.value = this.validatated();
    this.dispatch("update", { prefix: "number-input", target: this.inputTarget });
  }

  increment() {
    this.inputTarget.value = this.valueAsNumber + this.step;
    this.inputTarget.dispatchEvent(new Event("input"));
  }

  decrement() {
    this.inputTarget.value = this.valueAsNumber ? this.valueAsNumber - this.step : 0;
    this.inputTarget.dispatchEvent(new Event("input"));
  }

  validatated() {
    const newValue = parseInt(this.value.replace(/\D/g, "") || 0, 10);

    if (this.min !== undefined && newValue < this.min) {
      return this.min;
    }

    if (this.max !== undefined && newValue > this.max) {
      return this.max;
    }

    return newValue;
  }

  get value() {
    return this.inputTarget.value;
  }

  get valueAsNumber() {
    return Number(this.inputTarget.value) || 0;
  }

  get min() {
    return parseInt(this.inputTarget.min, 10) || undefined;
  }

  get max() {
    return parseInt(this.inputTarget.max, 10) || undefined;
  }

  get step() {
    return Number(this.inputTarget.step) || 1;
  }
}
