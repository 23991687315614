/* eslint-env browser */

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["credentialCatalog", "form", "modal", "required", "warning"];

  warnOrSubmit() {
    if (this.missingFieldPresent()) {
      this.highlightBlankRequiredFields();
    } else if (this.credentialWarningNeeded()) {
      this.openCredentialConfirmation();
    } else {
      this.formTarget.requestSubmit();
    }
  }

  completeSubmit() {
    this.formTarget.requestSubmit();
  }

  missingFieldPresent() {
    return this.requiredTargets.some(this.blankAndEnabled);
  }

  blankAndEnabled(field) {
    const fieldset = field.closest("fieldset");
    const catalog = document.getElementById(fieldset.dataset.catalogId);
    return !fieldset.disabled && catalog.checked && field.value.trim().length < 1;
  }

  credentialWarningNeeded() {
    return this.warningTargets.some((element) => !element.checked);
  }

  highlightBlankRequiredFields() {
    this.clearPreviousErrors();
    this.requiredTargets
      .filter(this.blankAndEnabled)
      .forEach((field) => field.parentElement.querySelector(".required").classList.remove("hidden"));
  }

  clearPreviousErrors() {
    this.requiredTargets
      .filter((field) => !this.blankAndEnabled(field))
      .forEach((field) => {
        field.parentElement.querySelector(".required").classList.add("hidden");
        const error = field.closest(".catalog").querySelector(".credential-error");
        error?.classList?.add("hidden");
      });
  }

  openCredentialConfirmation() {
    this.updateCredentialWarning();
    this.modalTarget.showModal();
  }

  updateCredentialWarning() {
    const catalogName = this.getCredentialCatalogName();
    this.credentialCatalogTargets.forEach((field) => {
      field.textContent = catalogName;
    });
  }

  getCredentialCatalogName() {
    // Running the list of warningCatalogNames through a set ensures we only count each catalog once
    const warningCatalogNames = [
      ...new Set(this.warningTargets.filter((element) => !element.checked).map((element) => element.dataset.catalog)),
    ];
    if (warningCatalogNames.length > 1) {
      return `${warningCatalogNames.length} Catalogs`;
    }

    return warningCatalogNames[0];
  }
}
