/* eslint-env browser */
import { Controller } from "stimulus";
import { useApplication } from "stimulus-use";

export default class extends Controller {
  static classes = ["needToReceive", "partiallyReceived", "received"];

  static targets = ["button", "checkbox", "checkmark", "input", "invisibleLabel"];

  static values = {
    dirty: { type: Boolean, default: false },
    allocation: Number,
    initialAllocation: Number,
    maxAllocation: Number,
    status: String,
  };

  connect() {
    useApplication(this, { overwriteDispatch: false });

    this.emitEvents = true;
    this.stylableElements = [this.element, ...this.buttonTargets];

    this.statusClassMap = {
      need_to_receive: this.needToReceiveClasses,
      partially_received: this.partiallyReceivedClasses,
      received: this.receivedClasses,
    };

    this.stylableElements.forEach((element) => element.classList.add(...this.statusClassMap[this.statusValue]));
  }

  allocationValueChanged(value) {
    if (!this.isConnected) return;

    this.inputTarget.value = value;
    this.invisibleLabelTarget.textContent = value;

    this.dirtyValue = this.allocationValue !== this.initialAllocationValue;

    this.checkboxTarget.checked = this.dirtyValue;
    this.checkboxTarget.indeterminate = this.checkboxTarget.checked && this.allocationValue < this.maxAllocationValue;

    if (this.statusValue === "received") {
      this.statusValue = "need_to_receive";
    }

    if (this.emitEvents) {
      this.dispatchAllocationUpdate();
    }
  }

  statusValueChanged(value, previousValue) {
    this.element.dataset.status = value;

    this.checkmarkTarget.classList.toggle("hidden", value !== "received");
    this.checkboxTarget.classList.toggle("hidden", value === "received");

    if (!this.stylableElements) return;

    this.stylableElements.forEach((element) => {
      element.classList.remove(...this.statusClassMap[previousValue]);
      element.classList.add(...this.statusClassMap[value]);
    });
  }

  dispatchAllocationUpdate() {
    this.dispatch("update", { prefix: "allocation", target: this.element, detail: { instance: this } });
  }

  reset() {
    this.updateQuietly(this.initialAllocationValue);
  }

  toggle() {
    this.allocationValue = this.checkboxTarget.checked ? this.maxAllocationValue : this.initialAllocationValue;
  }

  update() {
    this.allocationValue = parseInt(this.inputTarget.value, 10);
  }

  updateQuietly(value) {
    this.emitEvents = false;
    this.allocationValue = value;

    setTimeout(() => {
      this.emitEvents = true;
    });
  }

  get isDependentControl() {
    return !this.isMainControl;
  }

  get isMainControl() {
    return this.inputTarget.name === "";
  }

  get received() {
    return this.allocationValue >= this.maxAllocationValue;
  }
}
