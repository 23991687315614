/* eslint-env browser */
import { Tabs } from "tailwindcss-stimulus-components";

export default class extends Tabs {
  static values = {
    useAnchor: { type: Boolean, default: true },
  };

  get anchor() {
    if (!this.useAnchorValue) return null;

    return super.anchor;
  }
}
