/* eslint-env browser */

import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    countId: String,
    total: Number,
  };

  connect() {
    this.updateCount();
  }

  update(event) {
    if (event.target.checked) {
      this.totalValue += 1;
    } else {
      this.totalValue -= 1;
    }
    this.updateCount();
  }

  updateCount() {
    if (this.totalValue > 0) {
      this.counter.innerHTML = `(${this.totalValue})`;
    } else {
      this.counter.innerHTML = "";
    }
  }

  get counter() {
    return document.getElementById(this.countIdValue);
  }
}
