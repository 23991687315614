/* eslint-env browser */
import { Controller } from "stimulus";
import { constrain } from "../../helpers/numbers";

export default class extends Controller {
  static outlets = ["goods--receivable-card", "goods--receivable-good"];

  static targets = ["actionsBar", "selectedActions", "filteringActions", "selectedItemCount"];

  static values = {
    selectedOneLabel: String,
    selectedManyLabel: String,
  };

  cancelSelections() {
    this.receivableCards.forEach((card) => card.reset());
    this.receivableGoods.forEach((receivable) => receivable.reset());

    this.actionsBarTarget.dataset.display = "filters";
  }

  configureToolbar() {
    const allocationAdjusted = this.receivableGoods.some((receivable) => receivable.dirtyValue);

    const totalAllocated = this.receivableGoods
      .filter((receivable) => receivable.isMainControl)
      .reduce(
        (value, receivable) => Math.abs(value + (receivable.allocationValue - receivable.initialAllocationValue)),
        0,
      );

    const selected = totalAllocated === 1 ? this.selectedOneLabelValue : this.selectedManyLabelValue;

    this.selectedItemCountTarget.textContent = `${totalAllocated} ${selected}`;
    this.actionsBarTarget.dataset.display = allocationAdjusted ? "selected" : "filters";
  }

  syncDependentFields(event) {
    const targetReceivable = event.detail.instance;
    const container = targetReceivable.element.closest("[data-component=groupRow]");

    const receivableGoods = this.receivableGoods.filter(
      (receivable) =>
        container.contains(receivable.element) &&
        receivable.element.dataset.size === targetReceivable.element.dataset.size,
    );

    if (targetReceivable.isMainControl) {
      // The main control for a good size was adjusted, so adjust any of its grouped controls.
      const leftover = receivableGoods
        .filter((receivable) => receivable.isDependentControl)
        .reduce((value, receivable) => {
          const newValue = constrain(value, 0, receivable.maxAllocationValue);
          receivable.updateQuietly(newValue);

          return value - newValue;
        }, targetReceivable.allocationValue);

      receivableGoods[1].updateQuietly(receivableGoods[1].allocationValue + Math.max(0, leftover));
    } else {
      // A grouped control for a good size was adjusted, so adjust the main control as well.
      receivableGoods[0].updateQuietly(
        receivableGoods
          .filter((receivable) => receivable.isDependentControl)
          .reduce((value, receivable) => value + receivable.allocationValue, 0),
      );
    }

    this.#syncRowCheckboxWithReceivableCheckboxes(container);
    this.#syncCardCheckboxWithRowCheckboxes(container.closest("[role=group]"));
    this.configureToolbar();
  }

  get receivableCards() {
    return this.goodsReceivableCardOutlets;
  }

  get receivableGoods() {
    return this.goodsReceivableGoodOutlets;
  }

  #syncRowCheckboxWithReceivableCheckboxes(row) {
    const rowCheckbox = row.querySelector("[data-component=groupRowCheckbox]");
    const receivableGoods = this.receivableGoods.filter(
      (receivable) => row.contains(receivable.element) && receivable.isMainControl,
    );

    rowCheckbox.checked = receivableGoods.some((receivable) => receivable.dirtyValue);
    rowCheckbox.indeterminate = rowCheckbox.checked && !receivableGoods.every((receivable) => receivable.received);
  }

  #syncCardCheckboxWithRowCheckboxes(card) {
    const cardCheckbox = card.querySelector("[data-component='cardCheckbox']");
    const rowCheckboxes = [...card.querySelectorAll("[data-component='groupRowCheckbox']")];

    cardCheckbox.checked = rowCheckboxes.some((checkbox) => checkbox.checked);
    cardCheckbox.indeterminate =
      cardCheckbox.checked && !rowCheckboxes.every((checkbox) => checkbox.checked && !checkbox.indeterminate);
  }
}
