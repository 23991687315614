/* eslint-env browser */
import { Controller } from "stimulus";

export default class extends Controller {
  static outlets = ["goods--receivable-good"];

  static targets = ["allocation", "cardCheckbox", "rowCheckbox", "progressBar", "progressIndicator", "receivableInput"];

  static values = {
    initialAllocation: Number,
    allocation: Number,
    maxAllocation: Number,
  };

  allocationValueChanged(value) {
    this.allocationTarget.textContent = value;
    this.progressBarTarget.dataset.indicates = this.indicatorStatus;
    this.progressIndicatorTarget.style.width = `${this.selectedPercentage}%`;
  }

  reset() {
    this.allocationValue = this.initialAllocationValue;

    this.cardCheckboxTarget.indeterminate = false;
    this.cardCheckboxTarget.checked = false;

    this.rowCheckboxTargets.forEach((checkbox) => {
      checkbox.indeterminate = false;
      checkbox.checked = false;
    });
  }

  toggleCardCheckbox(event) {
    this.element.querySelectorAll("[data-component='groupRowCheckbox']").forEach((checkbox) => {
      checkbox.indeterminate = false;
      checkbox.checked = event.target.checked;
      checkbox.dispatchEvent(new Event("change"));
    });
  }

  toggleRowCheckbox(event) {
    const container = event.target.closest("[data-component='groupRow']");

    container.querySelectorAll("[data-component='goodsCheckbox']").forEach((checkbox) => {
      checkbox.indeterminate = false;
      checkbox.checked = event.target.checked;
      checkbox.dispatchEvent(new Event("click"));
    });

    this.goodsReceivableGoodOutlets
      .filter((receivable) => container.contains(receivable.element) && receivable.statusValue === "received")
      .forEach((receivable) => {
        receivable.statusValue = "need_to_receive";
      });
  }

  updateTotals() {
    this.allocationValue = this.receivableInputTargets.reduce(
      (value, receivable) => value + parseInt(receivable.value, 10),
      0,
    );
  }

  get selectedPercentage() {
    return Math.min(100, (this.allocationValue / this.maxAllocationValue) * 100);
  }

  get indicatorStatus() {
    if (!this.cardCheckboxTarget.indeterminate && this.selectedPercentage >= 100) {
      return "full";
    }

    if (this.selectedPercentage) {
      return "partial";
    }

    return "empty";
  }
}
